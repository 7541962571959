import honeyMigrationABI from '../abis/honeyMigration.abi.json';
import honeyTokenABI from '../abis/honeyToken.abi.json';
import oldHoneyTokenABI from '../abis/oldHoneyToken.abi.json';
import sepolia from './chains/sepolia';
import { chain } from 'wagmi';

const isTestnet = parseInt(process.env.REACT_APP_USE_TESTNET!, 10) === 1;

const config = {
  environment: process.env.NODE_ENV as 'production' | 'development',
  isTestnet,

  sentry: {
    sentryDsn: process.env.REACT_APP_SENTRY_DSN!,
  },

  ga: {
    gaId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID!,
  },

  infura: {
    infuraId: process.env.REACT_APP_INFURA_ID!,
  },

  quicknode: {
    rpcUrl: process.env.REACT_APP_QUICKNODE_RPC_URL!,
  },

  chains: isTestnet ? [sepolia] : [chain.mainnet],

  contracts: {
    oldHoneyTokenContract: {
      address: process.env.REACT_APP_OLD_HONEY_TOKEN_CONTRACT_ADDRESS!,
      interface: oldHoneyTokenABI,
    },
    honeyTokenContract: {
      address: process.env.REACT_APP_HONEY_TOKEN_CONTRACT_ADDRESS!,
      interface: honeyTokenABI,
    },
    honeyMigrationContract: {
      address: process.env.REACT_APP_HONEY_MIGRATION_CONTRACT_ADDRESS!,
      interface: honeyMigrationABI,
    },
  },

  urls: {
    publicUrl: process.env.PUBLIC_URL!,
    apiUrl: process.env.REACT_APP_API_URL!,
    buyEthUrl: process.env.REACT_APP_BUY_ETH_URL!,
    buyHoneyUrl: process.env.REACT_APP_BUY_HONEY_URL!,
    buyOldHoneyUrl: process.env.REACT_APP_BUY_OLD_HONEY_URL!,
    learnMoreUrl: process.env.REACT_APP_LEARN_MORE_URL!,
    discordUrl: process.env.REACT_APP_DISCORD_URL!,
    twitterUrl: process.env.REACT_APP_TWITTER_URL!,
  },
};

export default config;
