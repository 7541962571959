import * as React from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { Input, InputProps } from '@chakra-ui/input';

export type BigNumberInputProps = {
  decimals: number;
  value: string;
  max?: string;
  min?: string;
  onChange: (value: string) => void;
} & Omit<InputProps, 'onChange'>;

export function BigNumberInput({
  decimals,
  value,
  onChange,
  max,
  min,
  ...rest
}: BigNumberInputProps) {
  const inputRef = React.useRef<any>(null);

  const [inputValue, setInputvalue] = React.useState('');

  React.useEffect(() => {
    if (!value) {
      setInputvalue('');
    } else {
      let parseInputValue;

      try {
        parseInputValue = parseUnits(inputValue || '0', decimals);
      } catch {}

      if (!parseInputValue || !parseInputValue.eq(value)) {
        setInputvalue(formatUnits(value, decimals));
      }
    }
  }, [value, decimals, inputValue]);

  const updateValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    if (value === '') {
      onChange(value);
      setInputvalue(value);
      return;
    }

    let newValue: BigNumber;
    try {
      newValue = parseUnits(value, decimals);
    } catch {
      return;
    }

    const invalidValue = (min && newValue.lt(min)) || (max && newValue.gt(max));
    if (invalidValue) {
      return;
    }

    setInputvalue(value);
    onChange(newValue.toString());
  };

  return (
    <Input value={inputValue} ref={inputRef} onChange={updateValue} {...rest} />
  );
}
