import EtherAmount from '../components/EtherAmount';
import HoneyIcon from '../components/HoneyIcon';
import Panel from '../components/Panel';
import TinyHeading from '../components/TinyHeading';
import useTranslate from '../hooks/useTranslate';
import useWalletAssets from '../hooks/useWalletAssets';
import { BigNumberInput } from '../components/BigNumberInput';
import { useState } from 'react';
import {
  Box,
  Button,
  Container,
  HStack,
  Heading,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Text,
  useBoolean,
} from '@chakra-ui/react';

const ConnectedPage = () => {
  const translate = useTranslate();
  const [isFocused, { toggle: toggleFocused }] = useBoolean();
  const { oldHoneyBalance, honeyBalance } = useWalletAssets();
  const [amountToMigrate, setAmountToMigrate] = useState(
    oldHoneyBalance?.toString() || ''
  );

  return (
    <Container
      maxW="md"
      flex="1"
      pt="12"
      pb="16"
      justifyContent="center"
      display="flex"
      flexDirection="column"
    >
      <Panel pt="12" pb="8" px="8">
        <Box mb="8" pl="2" mt="1.5" textAlign="center">
          <Heading fontSize="3xl">{translate('honeyMigrate:title')}</Heading>

          <Text fontSize="sm" mt="2" opacity="0.8">
            {translate('honeyMigrate:description')}
          </Text>
        </Box>

        <HStack spacing="2" w="full">
          <InputGroup size="lg">
            <InputLeftAddon
              children={<HoneyIcon />}
              borderWidth="2px"
              borderColor={isFocused ? 'primary.500' : 'dark.500'}
              backgroundColor="transparent"
              color="primary.500"
              p="2"
              w="12"
              transitionDuration="0.2s"
            />

            <BigNumberInput
              value={amountToMigrate}
              decimals={18}
              min="0"
              max={oldHoneyBalance?.toString()}
              placeholder={translate('honeyMigrate:amountInputPlaceholder')}
              size="lg"
              pl="0"
              fontWeight="semibold"
              paddingRight="60px"
              borderLeftRadius="0"
              borderLeft="0"
              borderRightWidth="2px"
              onFocus={toggleFocused}
              onBlur={toggleFocused}
              onChange={setAmountToMigrate}
            />

            <InputRightElement mr="2">
              <Button
                borderLeftRadius="0"
                colorScheme="dark"
                onClick={() =>
                  setAmountToMigrate(oldHoneyBalance?.toString() || '')
                }
              >
                {translate('common:max')}
              </Button>
            </InputRightElement>
          </InputGroup>
        </HStack>

        <Button mt="4" w="full" onClick={() => {}}>
          {translate('honeyMigrate:approveButtonLabel')}
        </Button>
      </Panel>

      <Panel p="4" mt="8" bg="dark.700">
        <Heading fontSize="xl" py="2" mb="3" textAlign="center">
          {translate('honeyBalance:title')}
        </Heading>

        <HStack gap="2">
          <Box p="4" bg="dark.900" flex="1" w="50%" borderRadius="md">
            <TinyHeading opacity={1} color="dark.100">
              <Text display="inline">OLD</Text> $HONEY
            </TinyHeading>

            <Text>
              <EtherAmount amount={oldHoneyBalance} />
            </Text>
          </Box>

          <Box p="4" bg="dark.900" flex="1" w="50%" borderRadius="md">
            <TinyHeading opacity={1} color="dark.100">
              <Text color="primary.500" display="inline">
                NEW
              </Text>{' '}
              $HONEY
            </TinyHeading>
            <Text>
              <EtherAmount amount={honeyBalance} />
            </Text>
          </Box>
        </HStack>
      </Panel>
    </Container>
  );
};

export default ConnectedPage;
