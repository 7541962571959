const translations = {
  'common:copy': 'Copy',
  'common:copied': 'Copied to clipboard!',
  'common:disconnect': 'Disconnect',
  'common:free': 'Free',
  'common:claim': 'Claim',
  'common:buy': 'Buy',
  'common:refresh': 'Refresh',
  'common:cancel': 'Cancel',
  'common:noResults': 'No results…',
  'common:fancyBear': 'Fancy Bear',
  'common:fancyBears': 'Fancy Bears',
  'common:level': 'Level',
  'common:waitMessage': 'Please wait a moment for the metadata to be updated…',
  'common:tokenId': 'Token ID',
  'common:honey': '$HONEY',
  'common:migrate': 'Migrate',
  'common:max': 'Max',

  'error:default': 'Oops! Something went wrong.',

  'success:default': 'Success! Thank you.',

  'honeyMigrate:title': 'Migrate Your $HONEY',
  'honeyMigrate:description': 'Migrate your $HONEY tokens to the new contract.',
  'honeyMigrate:amountInputPlaceholder': '0.00',
  'honeyMigrate:approveButtonLabel': 'Approve',

  'honeyBalance:title': 'Your $HONEY Balance',
  'honeyBalance:newHoneyBalance': 'New $HONEY Tokens',
  'honeyBalance:oldHoneyBalance': 'Old $HONEY Tokens',

  'connect:title': 'Connect Your Wallet',
  'connect:appTitle': 'Migrate Your $HONEY',
  'connect:appDescription': 'Migrate your $HONEY tokens to the new contract.',
  'connect:button:label': 'Connect with {connector}',

  'footer:learnMore': 'About',
  'footer:powered': 'Powered by',
  'footer:contract': 'Contract',

  'walletPopup:title': 'Your Wallet',

  'unsupportedNetwork:title': 'Wrong Network',
  'unsupportedNetwork:description':
    'Your wallet is connected to an unsupported network. Please change it to: {chainName}',
  'unsupportedNetwork:switch': 'Switch Network',
};

export default translations;
